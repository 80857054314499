class LegalTabs {

  constructor() {
    this.$buttons = Array.prototype.slice.call(document.querySelectorAll('.lang-btn'));
    this.$panes = Array.prototype.slice.call(document.querySelectorAll('.tab-pane'));
    this.bindEvents();
    this.setInitial();
  }

  bindEvents() {
    let panes = this.$panes;
    let buttons = this.$buttons;
    buttons.forEach((btn) => {
      btn.addEventListener('click', function(e){
        e.preventDefault();
        buttons.forEach((btn) => {
          btn.classList.remove('lang-btn-active');
        });

        e.target.classList.add('lang-btn-active');
        
        panes.forEach((pane) => {
          pane.classList.remove('active');
        });

        document.querySelector(`#${e.target.dataset.lang}`).classList.add('active');
      })
    })
  }

  setInitial() {
    let initialBtn = this.$buttons.find((btn) => btn.dataset.initial === '');
    initialBtn.classList.add('lang-btn-active');
    document.querySelector(`#${initialBtn.dataset.lang}`).classList.add('active');
  }
}

if(document.querySelector('#legal')) {
  if( document.readyState !== 'loading' ) {
    window.legalTabs = new LegalTabs()
  } else {
    document.addEventListener('DOMContentLoaded', function () {
      window.legalTabs = new LegalTabs()
    });
  }
}

export default LegalTabs;